import { $api } from "./interceptor";
import { tasksStore } from "store/useTasksStore";
import { quizTaskStore } from "store/useQuizTaskStore";
import { taskDetailsStore } from "store/useTaskDetailsStore";
import { taskType } from "store/useCreateTaskFormStore";
import { createTaskFormStore } from "store/useCreateTaskFormStore";
import { toast } from "App";
import { quizTaskStoreInitialState } from "store/useQuizTaskStore";
import { createTaskFormStoreInitialState } from "store/useCreateTaskFormStore";

const newQuestion = {
  question: "",
  answers: [
    { text: "", correct: true },
    { text: "", correct: false },
  ],
};

const tasksApi = () => {
  const { setState, getState } = tasksStore;
  const { setState: set, getState: get } = quizTaskStore;

  const getTasks = async () => {
    try {
      const response = await $api.get("/api/tasks/list");
      setState({ loading: true });
      if (response.status === 200) {
        setState({
          tasks: response.data.data,
        });
      }
    } catch (error) {
    } finally {
      setState({ loading: false });
    }
  };

  const getPaginatedTasks = async ({
    page = 0,
    pageSize = 10,
    setPagesCount,
    searchValue,
  }) => {
    try {
      setState({ loading: true });
      const response = await $api.get("/api/tasks/list", {
        params: { page: page + 1, limit: pageSize, search: searchValue },
      });
      if (response.status === 200) {
        setState({
          tasks: response.data.data,
        });
        setPagesCount(response.data.meta.totalPages);
      }
    } catch (error) {
    } finally {
      setState({ loading: false });
    }
  };

  const getTask = async (taskID) => {
    const { tasks } = getState();
    if (!tasks?.length) {
      await getTasks();
    }

    const task = getState().tasks?.find(({ id }) => id === taskID);

    if (!task) {
      return;
    }

    taskDetailsStore.setState({
      description: task.description,
      availableFromLevel: task.availableFromLevel,
      title: task.title,
      rewardPointsAmount: task.rewardPointsAmount,
      isActive: task.status === "ACTIVE",
      redirectUrl: task.redirectUrl,
      resourceId: task.telegramResourceId,
      type: task.type,
      iconUrl: task.iconUrl,
      isFeaturedTask: task.isFeatured,
      relativeRewardPercent: task.relativeRewardPercent,
    });
  };

  const patchTask = async (taskID) => {
    const {
      description,
      availableFromLevel,
      title,
      rewardPointsAmount,
      isActive,
      redirectUrl,
      projectId,
      type,
      isFeaturedTask,
      iconUrl,
      relativeRewardPercent,
    } = taskDetailsStore.getState();
    const requestBody = {
      description,
      availableFromLevel,
      title,
      rewardPointsAmount,
      redirectUrl,
      isFeatured: isFeaturedTask,
      status: isActive ? "ACTIVE" : "INACTIVE",
      iconUrl: iconUrl ? iconUrl : null,
      relativeRewardPercent: relativeRewardPercent ? relativeRewardPercent : null
    };

    if (type === taskType.telegram) {
      requestBody.projectId = projectId;
    }

    try {
      await $api.patch(`api/tasks/${taskID}`, requestBody);

      toast({
        title: "Task updated.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // TODO: nice to have loader there
  const deleteTask = async (taskID) => {
    try {
      const response = await $api.delete(`api/tasks/${taskID}`);
      if (response.status === 200) {
        toast({
          title: "Task successfully deleted.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const clearTaskCreationStorages = () => {
    const { type, ...createTaskFormStoreFreshState } =
      createTaskFormStoreInitialState;
    set({ ...quizTaskStoreInitialState });
    createTaskFormStore.setState({ ...createTaskFormStoreFreshState });
  };

  const postQuizTask = async (payload) => {
    try {
      await $api.post("/tasks/quiz", payload);
      toast({
        title: "Quiz task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postGitCoinTask = async (payload) => {
    try {
      await $api.post("/tasks/gitcoin", payload);
      toast({
        title: "Gitcoin task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postDiscordTask = async (payload) => {
    try {
      await $api.post("/tasks/discord", payload);
      toast({
        title: "Discord task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error?.response?.data?.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postTwitterTask = async (payload) => {
    try {
      await $api.post("/tasks/twitter", payload);
      toast({
        title: "Twitter task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const postSnapshotTask = async (payload) => {
    try {
      await $api.post("/api/tasks/snapshot", payload);
      toast({
        title: "Snapshot task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postReferralTask = async (payload) => {
    try {
      await $api.post("/tasks/referral", payload);
      toast({
        title: "Referral task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postTelegramTask = async (payload) => {
    try {
      await $api.post("/api/tasks/telegram", payload);
      toast({
        title: "Telegram task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const postNftTask = async (payload) => {
    try {
      await $api.post("/tasks/nft", payload);
      toast({
        title: "NFT task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const postRegisterTelegramTask = async (payload) => {
    try {
      await $api.post("/tasks/register-with-telegram", payload);
      toast({
        title: "Register with Telegram task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postConnectBybitTask = async (payload) => {
    try {
      await $api.post("/tasks", payload);
      toast({
        title: "Connect Bybit task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postConnectWalletTask = async (payload) => {
    try {
      await $api.post("api/tasks/wallet", payload);
      toast({
        title: "Connect Wallet task successfully created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      clearTaskCreationStorages();
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const createTaskSubmit = () => {
    const { getState } = createTaskFormStore;
    const { getState: getQuizTaskStore } = quizTaskStore;

    const {
      title,
      description,
      bonus,
      type,
      link,
      redirectUrl,
      inviteCount,
      twitterTaskType,
      availableFromLevel,
      telegramResourceId,
      telegramRedirectUrl,
      ticketsCount,
      selectedProjectId,
      collectionContractAddress,
      networkId,
      coinAmount,
      discordResourceId,
      discordRedirectUrl,
      customRewards,
      maxSuccessfullyCompletions,
      registerWithTelegramRedirectUrl,
      verifyApiUrl,
      walletProvider,
      isFeaturedTask,
      order,
      iconUrl,
      relativeRewardPercent,
      count,
      category,
    } = getState();
    const { approximateTime, questions } = getQuizTaskStore();

    let payload = {
      task: {
        title,
        description,
        type,
        rewardPointsAmount: bonus,
        availableFromLevel,
        isFeatured: isFeaturedTask,
        order: order ? order : null,
        iconUrl: iconUrl ? iconUrl : null,
        count: count === 0 ? null : count,
        relativeRewardPercent: relativeRewardPercent? relativeRewardPercent : null,
        category: category ? category : null,
      },
    };

    if (ticketsCount) {
      payload.task.rewardTicketsAmount = ticketsCount;
    }
    if (selectedProjectId) {
      payload.task.projectId = selectedProjectId;
    }

    if (customRewards) {
      payload.task.customRewards = customRewards.split(",");
    }
    if (maxSuccessfullyCompletions) {
      payload.task.maxSuccessfullyCompletions = maxSuccessfullyCompletions;
    }
    
    switch (type) {
      case taskType.twitter:
        postSnapshotTask({ ...payload.task, redirectUrl });
        return;
      case taskType.quiz:
        payload.quiz = {
          approximateTime,
          questions,
        };
        postQuizTask(payload);
        return;
      case taskType.snapshot:
        postSnapshotTask({ ...payload.task, redirectUrl });
        return;
      case taskType.referrals:
        postSnapshotTask({ ...payload.task });
        return;
      case taskType.referral:
        payload.inviteCount = inviteCount;
        postReferralTask(payload);
        return;
      case taskType.telegram:
        payload.telegramTask = {
          telegramResourceId: Number(telegramResourceId),
          type: "SUBSCRIBE",
        };
        payload.task.redirectUrl = telegramRedirectUrl;
        postTelegramTask(payload);
        return;
      case taskType.nft:
        payload.nftTask = { collectionContractAddress, networkId };
        postNftTask(payload);
        return;

      case taskType.splittedQuiz:
        payload.quiz = {
          approximateTime,
          questions,
        };
        const { rewardPointsAmount, ...rest } = payload.task;
        payload.task = { ...rest, type: taskType.quiz };
        postQuizTask(payload);
        return;
      case taskType.gitCoin:
        payload.gitcoinAmount = Number(coinAmount);

        postGitCoinTask(payload);
        return;
      case taskType.discord:
        payload.discordTask = {
          resourceId: discordResourceId,
          type: "JOIN_SERVER",
        };
        payload.task.redirectUrl = discordRedirectUrl;
        postDiscordTask(payload);
        return;

      case taskType.registerTelegram:
        payload.registerWithTelegramTask = {
          verifyApiUrl,
        };
        payload.task.redirectUrl = registerWithTelegramRedirectUrl;
        postRegisterTelegramTask(payload);
        return;
      case taskType.connectBybitWallet:
        postConnectBybitTask({ ...payload.task });
        return;
      case taskType.wallet:
        payload.walletTask = {
          provider: walletProvider,
        };
        postConnectWalletTask(payload);
        return;
      default:
        return;
    }
  };

  const isQuestionsValid = () => {
    const { questions } = quizTaskStore.getState();
    const { type } = createTaskFormStore.getState();

    if (type === taskType.quiz || type === taskType.splittedQuiz) {
      const isAllQuestionsExists = questions.every(({ question }) => question);

      if (type === taskType.splittedQuiz) {
        const isAllRewardsSettled = questions.every(
          ({ rewardTicketsAmount, rewardPointsAmount }) =>
            rewardPointsAmount || rewardTicketsAmount
        );

        return isAllRewardsSettled && isAllQuestionsExists;
      }

      return isAllQuestionsExists;
    }

    return true;
  };

  const addAnswer = (questionIndex) => {
    const { questions } = get();

    set({
      questions: questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              answers: [...question.answers, { text: "", correct: false }],
            }
          : question
      ),
    });
  };

  const removeAnswer = (questionIndex) => {
    const { questions } = get();
    const newAnswers = questions[questionIndex].answers.slice(0, -1);

    set({
      questions: questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              answers: newAnswers,
            }
          : question
      ),
    });
  };

  const setAnswerText = (questionIndex, answerIndex, answerText) => {
    const { questions } = get();
    set({
      questions: questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              answers: question.answers.map((answer, j) =>
                answerIndex === j ? { ...answer, text: answerText } : answer
              ),
            }
          : question
      ),
    });
  };
  const setAnswerCorrect = (questionIndex, answerIndex, isCorrect) => {
    const { questions } = get();
    set({
      questions: questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              answers: question.answers.map((answer, j) =>
                answerIndex === j ? { ...answer, correct: isCorrect } : answer
              ),
            }
          : question
      ),
    });
  };

  const editQuestion = (questionIndex, questionText) => {
    const { questions } = get();

    set({
      questions: questions.map((question, i) =>
        i === questionIndex ? { ...question, question: questionText } : question
      ),
    });
  };
  const setQuestionPointsReward = (questionIndex, rewardPointsAmount) => {
    const { questions } = get();

    set({
      questions: questions.map((question, i) =>
        i === questionIndex ? { ...question, rewardPointsAmount } : question
      ),
    });
  };
  const setQuestionTicketsReward = (questionIndex, rewardTicketsAmount) => {
    const { questions } = get();

    set({
      questions: questions.map((question, i) =>
        i === questionIndex ? { ...question, rewardTicketsAmount } : question
      ),
    });
  };
  const setQuestionDescription = (questionIndex, description) => {
    const { questions } = get();

    set({
      questions: questions.map((question, i) =>
        i === questionIndex ? { ...question, description } : question
      ),
    });
  };

  const setApproximateTime = (approximateTime) => set({ approximateTime });
  const setQuestionsAmount = (questionsAmount) => set({ questionsAmount });

  const setQuestions = () => {
    const { questionsAmount, questions } = get();

    while (questions.length !== questionsAmount) {
      if (questions.length > questionsAmount) {
        questions.pop();
      } else {
        questions.push(newQuestion);
      }
    }

    set({ questions });
  };

  const handleSubmitOrder = async () => {
    try {
      const { orderTasks } = tasksStore.getState();

      await $api.patch("/api/tasks/orders", { updateTasks: orderTasks })
      await getTasks();
      tasksStore.setState({ orderTasks: [] });

      toast({
        title: "Order updated.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error occured.",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      tasksStore.setState({ orderTasks: [] });
    }
  }

  const uploadImage = async (file, isCreateTaskView = true) => {
    tasksStore.setState({ isSnapshotUploading: true });

    if (file && file instanceof Blob) {
      const newFormData = new FormData();
      newFormData.append("files", file, file.name);

      try {
        const uploadResponse = await $api.post("/api/files/upload", newFormData);

        const imageUrl = uploadResponse.data[0].imageUrl;

        if (isCreateTaskView) {
          createTaskFormStore.setState({ iconUrl: imageUrl });
        } else {
          taskDetailsStore.setState({ iconUrl: imageUrl });
        }

      } catch (error) {
        console.log(error);
        toast({
          title: "Error occured.",
          description: error.response.data.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } finally {
        tasksStore.setState({ isSnapshotUploading: false });
      }
    } else {
      tasksStore.setState({ isSnapshotUploading: false });
    }
  };

  return {
    getTasks,
    deleteTask,
    createTaskSubmit,
    getTask,
    patchTask,
    getPaginatedTasks,

    addAnswer,
    removeAnswer,
    setAnswerText,
    setAnswerCorrect,
    editQuestion,
    setApproximateTime,
    setQuestionsAmount,
    setQuestions,
    isQuestionsValid,
    setQuestionDescription,
    setQuestionPointsReward,
    setQuestionTicketsReward,
    clearTaskCreationStorages,
    handleSubmitOrder,
    uploadImage,
  };
};

export const tasksService = tasksApi();
