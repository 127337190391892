import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { tasksService } from "services/tasksService";
import { useNavigate, useParams } from "react-router-dom";
import { taskDetailsStore } from "../../../store/useTaskDetailsStore";
import { FormInput } from "./components/FormInput";
import { FormSwitch } from "./components/FormSwitch";
import { taskType } from "store/useCreateTaskFormStore";

export const TaskDetailsView = () => {
  const {
    title,
    isActive,
    description,
    rewardPointsAmount,
    redirectUrl,
    resourceId,
    type,
    isFeaturedTask,
    iconUrl,
    relativeRewardPercent,

    setTitle,
    setIsActive,
    setDescription,
    setRewardPointsAmount,
    setRedirectUrl,
    setResourceId,
    setIsFeaturedTask,
    setRelativeRewardPercent,
  } = taskDetailsStore();
  const { taskID } = useParams();
  const navigate = useNavigate();

  const [, setPickedImage] = useState(null);
  const [, setSnapshotFile] = useState(null);

  const handleImageChange = async (
    event
  ) => {
    const file = event.target.files && event.target.files[0];
    setPickedImage(null);

    if (!file?.type?.startsWith("image")) {
      return;
    }

    if (!file) {
      setPickedImage(null);
      return;
    }

    setSnapshotFile(file);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPickedImage(String(fileReader.result));
      tasksService.uploadImage(file, false)
    };

    fileReader.readAsDataURL(file);
  };

  useEffect(() => {
    tasksService.getTask(taskID);
  }, [taskID]);

  if (!taskID) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    tasksService.patchTask(taskID);
  };

  

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <form onSubmit={handleSubmit}>
        <Box>
          <FormInput
            name="title"
            handleChange={setTitle}
            value={title}
            required
          />
          <FormInput
            name="description"
            handleChange={setDescription}
            value={description}
            required
          />
          <FormInput
            name="rewardPointsAmount"
            handleChange={setRewardPointsAmount}
            value={rewardPointsAmount}
            type="number"
          />
          <FormInput
            name="RedirectURL"
            handleChange={setRedirectUrl}
            value={redirectUrl}
          />
          
          {type === taskType.telegram && (
            <FormInput
            name="Resource ID"
            handleChange={setResourceId}
            value={resourceId}
          />
          )}
           <FormInput
            name="Relative Reward Percent"
            handleChange={setRelativeRewardPercent}
            value={relativeRewardPercent}
            type="number"
          />
          <FormSwitch
            label={"Active"}
            value={isActive}
            handleChange={setIsActive}
          />
          <FormSwitch
            label={"Is Task Featured"}
            value={isFeaturedTask}
            handleChange={setIsFeaturedTask}
          />
        </Box>
        <Box 
          sx={{
          bgColor: "white",
          color: "black",
          cursor: "pointer",
          width: '200px',
          borderRadius: '10px',
          marginBottom: '10px'
        }}>
          <label htmlFor="choose-icon" style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '200px',
            height: '50px',
          }}>
          <input
            accept="image/*"
            type="file"
            id="choose-icon"
            onChange={handleImageChange}
            style={{display: 'none'}}
          />
          <p>Choose image</p>
          </label>
        </Box>
            {iconUrl && (
              <Box>
                <img src={iconUrl} alt="" />
                <button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    cursor: "pointer",
                    padding: '10px',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={() => taskDetailsStore.setState({ iconUrl: "" })}
                >
                  Delete
                </button>
              </Box>
            )}
        <Button colorScheme="teal" type="submit">
          Save
        </Button>
        <Button
          colorScheme="teal"
          onClick={() => {
            navigate("/tasks");
          }}
        >
          Go back to tasks
        </Button>
      </form>
    </Box>
  );
};
