import { userDetailsStore } from "store/useUserDetailsStore";
import { $api } from "./interceptor";
import { usersStore } from "store/useUsersStore";

const usersApi = () => {
  const { setState } = usersStore;

  const getUsersStatistic = async () => {
    try {
      const response = await $api.get("/users/statistic");
      setState({ loading: true });
      if (response.status === 200) {
        setState({
          statistic: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersList = async () => {
    try {
      setState({
        isUsersLoading: true,
      });
      const response = await $api.get("/users/list");
      setState({ loading: true });
      if (response.status === 200) {
        setState({
          users: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setState({
        isUsersLoading: false,
      });
    }
  };

  const getUsersCount = async () => {
    try {
      const {data} = await $api.get("/api/users/count");
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const getPaginatedUsersList = async ({
    page = 0,
    pageSize = 10,
    setPagesCount,
    searchValue,
  }) => {
    try {
      setState({
        isUsersLoading: true,
      });
      const response = await $api.get("/users/list", {
        params: { page: page + 1, limit: pageSize, search: searchValue },
      });

      if (response.status === 200) {
        setState({
          users: response.data.data,
        });
        setPagesCount(response.data.meta.totalPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setState({
        isUsersLoading: false,
      });
    }
  };

  const getUser = async (userID) => {
    try {
      const response = await $api.get("/users/list", {
        params: { search: userID },
      });

      const user = response.data.data?.at(0);

      if (!user) {
        return;
      }
      userDetailsStore.setState({
        firstName: user.profile?.name,
        lastName: user.profile?.surname,
        email: user.email,
        userType: user.role,
        wallet: user.connectedWallets[0] || "",
        KYC: user?.kyc?.status === "APPROVED",
        level: user.levelData?.currentLevel?.value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const patchUser = async (userID) => {
    const { userType, firstName, lastName } = userDetailsStore.getState();
    const isKol = userType === "KOL";
    const payload = {
      updateProfileOptions: {
        name: firstName,
        surname: lastName,
      },
      updateUserOptions: {},
    };

    if (isKol) {
      payload.updateUserOptions.isKol = true;
    } else {
      payload.updateUserOptions.role = userType;
      payload.updateUserOptions.isKol = false;
    }

    try {
      await $api.patch(`/users/${userID}`, payload);
    } catch (error) {}
  };

  return {
    getUsersStatistic,
    getUsersList,
    getUsersCount,
    getUser,
    patchUser,
    getPaginatedUsersList,
  };
};

export const usersService = usersApi();
