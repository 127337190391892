import { create, useStore } from "zustand";

export const userDetailsStore = create((set) => ({
  firstName: "",
  lastName: "",
  email: "",
  userType: "",
  wallet: "",
  KYC: false,
  level: 0,
  setFirstName: (firstName) => set({ firstName }),
  setLastName: (lastName) => set({ lastName }),
  setUserType: (userType) => set({ userType }),
}));

export const useUserDetailsStore = () => useStore(userDetailsStore);
