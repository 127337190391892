import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { tasksService } from "services/tasksService";
import { useTasksStore } from "store/useTasksStore";
import { TableWithHeader } from "./components/TableWithHeader";
import { CgTrash } from "react-icons/cg";
import { DeleteTaskSubmit } from "./components/DeleteTaskSubmit";
import { Link, useNavigate } from "react-router-dom";
import { useServerSidePagination } from "hooks/useServerSidePagination";
import { FormInput } from "./components/FormInput";

export const TasksView = () => {
  const { tasks, loading, orderTasks, setOrderTask, isSetOrderPressed } = useTasksStore();
  const navigate = useNavigate();
  const { refetch, tableStore, tableService } = useServerSidePagination(
    tasksService.getPaginatedTasks
  );

  const [deleteTaskInfo, setDeleteTaskInfo] = useState(null);

  const handleCreateLayout = () => {
    navigate("/tasks/create");
  };

  const handleTrashIconClick = (name, id) => {
    setDeleteTaskInfo({ name, id });
  };

  const handleClearDeleteTaskInfo = () => {
    setDeleteTaskInfo(null);
  };

  const columns = [
    {
      name: "Title",
      accessor: "title",
      Cell: ({
        row: {
          original: { id, title },
        },
      }) => {
        return <Link to={`${id}`}>{title}</Link>;
      },
    },
    { name: "Type", accessor: "type" },
    { name: "Bonus", accessor: "rewardPointsAmount" },
    { name: "Order", accessor: "order", Cell: ({
      row: {
        original: { id, title, order },
      },
    }) => {
      const clickedTask = orderTasks.find((t) => t.taskId === id);
      
      const validOrder = clickedTask ? clickedTask.order : order;
      return (
        <>
          {isSetOrderPressed ? (
            <FormInput
            name={"Set Order"}
            handleChange={(newOrder) => setOrderTask(id, newOrder)}
            value={validOrder}
            type="number"
            min={0}
          />
          ): (
            <p>{order}</p>
          )}
        </>
      );
    },},
    {
      name: "Actions",
      id: "actions",
      Cell: ({
        row: {
          original: { id, title },
        },
      }) => {
        return (
          <CgTrash
            cursor={"pointer"}
            color="red"
            width={24}
            height={24}
            onClick={() => {
              handleTrashIconClick(title, id);
            }}
          />
        );
      },
    },
  ];

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <TableWithHeader
        columns={columns}
        tableData={tasks}
        tableName={"Create a Task"}
        onTopBarButtonClick={handleCreateLayout}
        topBarButtonText={"Create task"}
        isDataLoading={loading}
        tableStore={tableStore}
        tableService={tableService}
      />

      <DeleteTaskSubmit
        isOpen={!!deleteTaskInfo}
        taskTitle={deleteTaskInfo?.name}
        handleClose={handleClearDeleteTaskInfo}
        handleSubmit={async () => {
          if (deleteTaskInfo?.id) {
            await tasksService.deleteTask(deleteTaskInfo.id);
            await refetch();
            handleClearDeleteTaskInfo();
          }
        }}
      />
    </Box>
  );
};
