import { create, useStore } from "zustand";

const initialState = {
  title: "",
  description: "",
  rewardPointsAmount: 0,
  availableFromLevel: 0,
  redirectUrl: "",
  resourceId: "",
  type: "",
  isActive: true,
  isFeaturedTask: false,
  iconUrl: null,
  relativeRewardPercent: null,
};

export const taskDetailsStore = create((set) => ({
  ...initialState,

  setTitle: (title) => set({ title }),
  setDescription: (description) => set({ description }),
  setRewardPointsAmount: (rewardPointsAmount) => set({ rewardPointsAmount }),
  setAvailableFromLevel: (availableFromLevel) => set({ availableFromLevel }),
  setRedirectUrl: (redirectUrl) => set({ redirectUrl }),
  setResourceId: (resourceId) => set({ resourceId }),
  setIsActive: (isActive) => set({ isActive }),
  setIsFeaturedTask: (isFeaturedTask) => set({ isFeaturedTask }),
  setIconUrl: (iconUrl) => set({ iconUrl }),
  setRelativeRewardPercent: (relativeRewardPercent) => set({ relativeRewardPercent }),
}));

export const useTaskDetailsStore = () => useStore(taskDetailsStore);
