import { Box, Button, Flex, Select } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usersService } from "services/usersService";
import { useUserDetailsStore } from "store/useUserDetailsStore";
import { FormInput } from "../components/FormInput";

export const UserDetailsView = () => {
  const {
    firstName,
    lastName,
    email,
    userType,
    wallet,
    KYC,
    level,
    setFirstName,
    setLastName,
    setUserType,
  } = useUserDetailsStore();
  const { userID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    usersService.getUser(userID);
  }, [userID]);

  if (!userID) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    usersService.patchUser(userID);
  };

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <form onSubmit={handleSubmit}>
        <Flex direction={"column"} gap={3}>
          <FormInput
            name="firstName"
            handleChange={setFirstName}
            value={firstName}
          />
          <FormInput
            name="lastName"
            handleChange={setLastName}
            value={lastName}
          />
          <Flex gap={5}>
            <Box minW={100}>Email:</Box>
            <Box>{email}</Box>
          </Flex>
          <Flex gap={5}>
            <Box minW={100}>User type:</Box>
            <Select
              maxW={200}
              value={userType}
              onChange={(e) => {
                setUserType(e.currentTarget.value);
              }}
              required
            >
              <option value="USER">User</option>
              <option value="KOL">KOL</option>
              <option value="ADMIN">Admin</option>
            </Select>
          </Flex>
          <Flex gap={5}>
            <Box minW={100}>wallet:</Box>
            <Box>{wallet.address}</Box>
          </Flex>
          <Flex gap={5}>
            <Box minW={100}>KYC:</Box>
            <Box>{KYC ? "verified" : "unverified"}</Box>
          </Flex>
          <Flex gap={5}>
            <Box minW={100}>level:</Box>
            <Box>{level}</Box>
          </Flex>
        </Flex>
        <Button colorScheme="teal" type="submit">
          Save
        </Button>
        <Button
          colorScheme="teal"
          onClick={() => {
            navigate("/users");
          }}
        >
          Go back to users list
        </Button>
      </form>
    </Box>
  );
};
