import { create, useStore } from "zustand";

export const walletProviders = {
  BINANCE: "BINANCE",
  OKX: "OKX",
  OKXWALLET: "OKX_WALLET",
  BYBIT: "BYBIT",
  HTX: "HTX",
  KUCOIN :"KUCOIN",
  MEXC: "MEXC",
  BITGET: "BITGET",
  TRUST_WALLET: "TRUST_WALLET",
  ONEINCH: "1INCH",
  OTHER: "OTHER",
}

export const taskType = {
  twitter: "TWITTER",
  quiz: "QUIZ",
  snapshot: "SNAPSHOT",
  referral: "REFERRAL",
  telegram: "TELEGRAM",
  nft: "NFT",
  splittedQuiz: "QUIZ2",
  gitCoin: "GITCOIN",
  discord: "DISCORD",
  registerTelegram: "REGISTER_WITH_TELEGRAM",
  connectBybitWallet: "CONNECT_BYBIT_WALLET",
  wallet: "WALLET",
  referrals: "REFERRALS"
};
const twitterTaskType = {
  subscribe: "SUBSCRIBE",
  like: "LIKE_POST",
  retweet: "RETWEET_POST",
};

export const createTaskFormStoreInitialState = {
  title: "",
  description: "",
  bonus: 0,
  count: 0,
  category : "",
  availableFromLevel: 0,
  type: taskType.telegram,
  link: "",
  redirectUrl: "",
  twitterTaskType: twitterTaskType.subscribe,
  inviteCount: 10,
  telegramResourceId: "",
  telegramRedirectUrl: "",
  selectedProjectId: "",
  ticketsCount: 0,
  collectionContractAddress: "",
  networkId: "",
  coinAmount: 0,
  discordResourceId: "",
  discordRedirectUrl: "",
  customRewards: "",
  maxSuccessfullyCompletions: null,
  registerWithTelegramRedirectUrl: "",
  verifyApiUrl: "",
  walletProvider: walletProviders.BINANCE,
  isFeaturedTask: false,
  order: null,
  iconUrl: "",
  relativeRewardPercent: null,
};

export const createTaskFormStore = create((set) => ({
  ...createTaskFormStoreInitialState,

  setTitle: (title) => set({ title }),
  setDescription: (description) => set({ description }),
  setBonus: (bonus) => set({ bonus }),
  setCount: (count) => set({ count }),
  setCategory: (category) => set({ category }),
  setType: (type) => set({ type }),
  setLink: (link) => set({ link }),
  setRedirectUrl: (redirectUrl) => set({ redirectUrl }),
  setTwitterTaskType: (twitterTaskType) => set({ twitterTaskType }),
  setAvailableFromLevel: (availableFromLevel) => set({ availableFromLevel }),
  setInviteCount: (inviteCount) => set({ inviteCount }),
  setTelegramResourceId: (telegramResourceId) => set({ telegramResourceId }),
  setTelegramRedirectUrl: (telegramRedirectUrl) => set({ telegramRedirectUrl }),
  setSelectedProjectId: (selectedProjectId) => set({ selectedProjectId }),
  setTicketsCount: (ticketsCount) => set({ ticketsCount }),
  setCollectionContractAddress: (collectionContractAddress) =>
    set({ collectionContractAddress }),
  setNetworkId: (networkId) => set({ networkId }),
  setCoinAmount: (coinAmount) => set({ coinAmount }),
  setDiscordResourceId: (discordResourceId) => set({ discordResourceId }),
  setDiscordRedirectUrl: (discordRedirectUrl) => set({ discordRedirectUrl }),
  setCustomRewards: (customRewards) => set({ customRewards }),
  setMaxSuccessfullyCompletions: (maxSuccessfullyCompletions) =>
    set({ maxSuccessfullyCompletions }),
  setRegisterWithTelegramRedirectUrl: (registerWithTelegramRedirectUrl) =>
    set({ registerWithTelegramRedirectUrl }),
  setVerifyApiUrl: (verifyApiUrl) => set({ verifyApiUrl }),
  setWalletProvider: (walletProvider) => set({ walletProvider }),
  setIsFeaturedTask: (isFeaturedTask) => set({ isFeaturedTask }),
  setOrder: (order) => set({ order }),
  setIconUrl: (iconUrl) => set({ iconUrl }),
  setRelativeRewardPercent: (relativeRewardPercent) => set({ relativeRewardPercent }),
}));

export const useCreateTaskFormStore = () => useStore(createTaskFormStore);
